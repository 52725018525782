import { keycloak } from "./keycloak";

export async function fetchJSON (path, action) {
  try {
    await keycloak.updateToken();
    const response = await fetch(
      `${process.env.PUBLIC_URL}/api/v1/${path}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // Authorization: `Bearer ${keycloak.token}`,
        },
        body: JSON.stringify(action),
      }
    );
    // Get the response as JSON
    return await response.json();
  } catch (err) {
    console.error('Failed to refresh token: ', err);
    window.location.reload();
  }
}

export async function fetchGraphQL(text, variables) {
  await keycloak.updateToken();
  // Fetch data from Hasura GraphQL API:
  const response = await fetch(
    `${process.env.PUBLIC_URL}/api/v1/graphql`,
    {
      method: 'POST',
      headers: {
        // 'X-Hasura-Admin-Secret': 'myadminsecretkey',
        // 'x-hasura-default-role': 'user',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${keycloak.token}`,
      },
      body: JSON.stringify({
        query: text,
        variables,
      }),
    }
  );
  // Get the response as JSON
  return await response.json();
}

export function unlockVideo(id, password, user) {
  return fetchJSON(
    'video',
    {
      action: 'UNLOCK',
      id: id,
      password: password,
      user_id: user
    }
  );
};

export function fetchGraphQLRedux(type, text, variables) {
  console.info(`${process.env.PUBLIC_URL}/api/v1/graphql`);
  return function (dispatch = function () { }) {
    dispatch({
      type: type,
    });
    return new Promise((resolve, reject) => {
      return fetchGraphQL(
        text, variables
      ).then(
        response => {
          dispatch({
            type: `${type}_OK`,
            data: response.data,
          });
          // if (response.success === true) {
          //   dispatch({
          //     type: `${type}_OK`,
          //     data: response.data,
          //   });
          // } else {
          //   dispatch({
          //     type: `${type}_ERROR`,
          //     data: response.data,
          //   });
          // }
          resolve(response, dispatch);
        }
      ).catch(function (error) {
        debugger;
        dispatch({
          type: `${type}_CONNECTION_ERROR`,
          error: error,
        });
        reject(error, dispatch);
      });
    });
  };
};

export async function uploadFile(file, properties={}) {
  const data = new FormData();
  data.append('file', file);
  for (let property in properties) {
    if (properties.hasOwnProperty(property)) {
      data.append(property, properties[property]);
    }
  }
  // console.log(data);
  const response = await window.fetch(
    `${process.env.PUBLIC_URL}/api/v1/obj`,
    {
      method: 'POST',
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      // headers: {
      //   'Content-Type': 'multipart/form-data'
      // },
      redirect: 'follow', // manual, *follow, error
      referrer: 'no-referrer', // no-referrer, *client
      body: data
    }
  );
  return response.json();
};

export function uploadVideo(file) {
  return uploadFile(
    file,
    {
      action: 'UPLOAD_VIDEO',
    },
  );
}

export function filterMenu(){
  return fetchGraphQL(`
    query {
      bdi_categories {
        name, description
      }
    }
  `);
}

export function getDepartments(entity='SUPSI'){
  return fetchGraphQL(`
    query {
      bdi_categories (
        where: {
          path: {
            _matches: "${entity}.*{1,1}"
          }
        }
      ){
        id,
        name,
        description,
        path
      }
    }
  `);
}

export function getActivities(departments){
  let where = '';
  if (departments.length>0){
    where = `(
        where: {
          path: {
            _matches_any: ["${departments.map(
                e => `${e.path}.*{1,2}`
              ).join('", "')}"]
          }
        }
      )
    `
  }
  console.log(`
    query {
      bdi_categories ${where}{
        id,
        name,
        description,
        path
      }
    }
  `);
  return fetchGraphQL(`
    query {
      bdi_categories ${where}{
        id,
        name,
        description,
        path
      }
    }
  `);
}

export function getVideo(id){

  return fetchGraphQL(`
    query getVideo($id: uuid!){
      status: bdi_video_status(
        where: {
          id_vid: {
            _eq: $id
          }
        }
      ) {
        approved
        availability
        access
        responsible
      }
      details: bdi_videos_by_pk (id: $id) {
        id
        creation_date
        title
        description
        file
        lang {
          name
          id
        }
        authors {
          id
          name
          affiliation
        }
        collocation: coll {
          name
          id
          description
          path
        }
        typology: typ {
          name
          id
          description
        }
        axs {
          id
          name
          description
        }
        link_icorsi
        link_msteams
        links {
          url
          text
        }
      }
      collection: bdi_categories(
        where: {
          vid_cats: {
            typ: {_eq: "collection"},
            vid: {_eq: $id},
            approved: {_eq: true}
          }
        }
      ) {
        name
        id
      }
      keywords: bdi_keywords(
        where:{
          vid_keys: {
            vid: {_eq: $id}
          }
        }
      ) {
        id,
        text
      }
    }
  `, {
    id: id
  });
}

export function initSearchPage() {
  return fetchGraphQLRedux(
    'INIT_FILTERS',
    `query {
      keywords: bdi_keywords(
        order_by: {
          text: asc
        },
        distinct_on: text,
        where: {
          vid_keys: {
            vid: {
              _is_null: false
            }
          }
        }
      ) {
        id
        name: text
      }
      languages: bdi_categories (
        where: {
          typ: {
            _eq: "language"
          }
        }
        order_by: {
          position: asc
        }
      ) {
        id,
        name,
        description,
        path
      }
      typologies: bdi_categories (
        where: {
          typ: {
            _eq: "typology"
          }
        }
        order_by: {
          name: asc
        }
      ) {
        id,
        name,
        description,
        path
      }
      collections: bdi_categories (
        where: {
          typ: {
            _eq: "collection"
          }
        }
        order_by: {
          name: asc
        }
      ) {
        id,
        name,
        description,
        path
      }
      collocations: bdi_categories (
        where: {
          typ: {
            _eq: "collocation"
          }
        }
        order_by: {
          path: asc
        }
      ) {
        id,
        name,
        description,
        path
      }
      accesses: bdi_categories (
        where: {
          typ: {
            _eq: "access"
          }
        }
        order_by: {
          position: asc
        }
      ) {
        id,
        name,
        description
      }
      licenses: bdi_categories (
        where: {
          typ: {
            _eq: "license"
          }
        }
        order_by: {
          position: asc
        }
      ) {
        id,
        name,
        description
      }
    }
  `);
}

export function listVideos(where, page, limit = 10) {
  let pageFilter = '';
  if (page) {
    pageFilter = `limit: ${limit}, offset: ${page * limit}, `;
  }
  if (where.length) {
    pageFilter = `(${pageFilter} where: {${where.join(', ')}})`;
  }
  return fetchGraphQL(`
    query {
      bdi_videos ${pageFilter} {
        id
        title
        description
        file
        vid_cats(where: {typ: {_eq: "collection"}}) {
          category {
            name
          }
        }
      }
    }
  `);
}
