import React from 'react';
import PropTypes from 'prop-types';

const VideoCard = props => {
  const {
    file,
    title,
    // description,
    vid_cats,
  } = props.video;

  if (props.horizontal === true) {
    return (
      <div
        className='mrgb fxrow'
        onClick={() => props.onSelected(props.video)}
        style={{
          cursor: 'pointer'
        }}
      >
        <div
          style={{
            // height: '100px',
            width: '250px',
            // display: 'flex',
            // justifyContent: 'center',
            // alignItems: 'center',
            // overflow: 'hidde',
          }}
        >
          <img
            alt='placeholder'
            src={`/api/v1/content/${file}.jpg`}
            style={{
              // height: '100px',

              // flexShrink: '0',
              // minWidth: '100%',
              // minHeight: '100%',
              // maxHeight: '100%',

              objectFit: 'cover',
              width: '170px',
              height: '100px',
            }}
          />
        </div>
        <div
          className='pdg'
        >
          <div
            className='txt-subtitle'
          >
            {
              vid_cats.map(
                (collection, idx) => (
                  <div
                    // className="linker"
                    key={`web-bdigital-video-collections-${idx}`}
                  >
                    {collection.category.name}
                  </div>
                )
              )
            }
          </div>
          <div
            className='txt-title'
            style={{
              margin: '0.25em 0px 0.5em 0px',
            }}
          >
            {title}
          </div>
          {/* <div
            className='card-description'
            style={{
              fontSize: '0.9em',
            }}
          >
            {description}
          </div> */}
        </div>
      </div>
    );
  }

  return (
    <div
      className='mrg fxcol card-selectable'
      onClick={() => props.onSelected(props.video)}
      style={{
        maxWidth: '350px',
        minWidth: '350px',
      }}
    >
      <div
        style={{
          boxShadow: "0px 5px 8px 0px rgba(0,0,0,0.6)",
        }}
      >
        <img
          alt='placeholder'
          src={`/api/v1/content/${file}.jpg`}
          style={{
            objectFit: 'cover',
            height: '200px',
            width: '350px',
            maxWidth: '350px',
          }}
        />
      </div>
      <div
        className='pdg'
      >
        <div
          className='txt-subtitle'
        >
          {
            vid_cats.map(
              (collection, idx) => (
                <div
                  // className="linker"
                  key={`web-bdigital-video-collections-${idx}`}
                >
                  {collection.category.name}
                </div>
              )
            )
          }
        </div>
        <div
          className='txt-title'
          style={{
            margin: '0.25em 0px 0.5em 0px'
          }}
        >
          {title}
        </div>
        {/* <div
          className='card-description'
        >
          {description}
        </div> */}
      </div>
    </div>
  );
};

VideoCard.propTypes = {
  video: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string
  }),
  horizontal: PropTypes.bool,
  onSelected: PropTypes.func
};

export default VideoCard;