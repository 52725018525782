import React from 'react'
import PropTypes from 'prop-types';
import {
  withRouter
} from "react-router-dom";
import { connect } from 'react-redux';
import { keycloak } from "../keycloak";


class HeaderCmp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: ''
    };
    // this.inputField = React.createRef();
    this.handleChange = this.handleChange.bind(this);
  }

  // componentDidMount() {
  //   this.inputField.current.focus();
  // }

  handleChange (e) {
    const {
      onChanged
    } = this.props;
    let value = e.target.value;
    this.setState({
      searchText: value
    }, () => {
      if (onChanged) {
        onChanged(value);
      }
    });
  }

  render() {
    return (
      <div
        className='pdg-x2 fxrowc'
        style={{
          height: '65px',
          // color: 'white',
          // backgroundColor: 'black',
          borderBottom: 'thin solid #d5d5d5',
          // -webkit-box-shadow: 0px 6px 12px -3px rgba(0,0,0,0.67); 
          // boxShadow: "0px 4px 8px -4px rgba(0,0,0,0.67)",
        }}
      >
        <div
          onClick={() => this.props.history.push(process.env.PUBLIC_URL)}
          style={{
            cursor: 'pointer'
          }}
        >
          <div
            style={{
              // fontFamily: "'Open Sans', sans-serif",
              fontSize: '2em',
              fontWeight: 'bold',
              lineHeight: '0.8em',
            }}
          >
            bDigital
          </div>
          <div
            className="txt-subtitle"
          >
            by SUPSI
          </div>
        </div>
        {/* <div
          className='fxfill'
        />
        <div
          className='mrgr fxrow'
        >
          <input
            onChange={this.handleChange}
            style={{
              width: '600px',
              height: '32px',
            }}
            placeholder="Ricerca testuale..."
            ref={this.inputField}  
            type='text'
            onChange={
              ev => {
                this.props.updateTextSearch(ev.target.value)
              }
            }
            value={this.state.value}  
          />
          <button
            style={{
              height: '32px',
            }}
          >
            Cerca
          </button>
        </div> */}
        <div
          className='fxfill'
        />
        {/* <div
          className='mrgr pdgr linker'
          style={{
            color: 'white',
            whiteSpace: 'nowrap',
          }}
          onClick={
            () => {
              this.props.history.push(
                process.env.PUBLIC_URL + "/search"
              );
            }
          }
        >
          Ricerca avanzata
        </div> */}
        <div
          style={{
            whiteSpace: 'nowrap',
            textAlign: 'right',
            lineHeight: '0.9em',
          }}
        >
          <span
            style={{
              fontSize: '0.8em',
              color: 'rgb(77, 77, 77)',
            }}
          >
            {
              this.props.user.group?
                this.props.user.group[0] === '/editor'?
                  'Docente':
                  this.props.user.group[0] === '/student'?
                    'Studente':
                    this.props.user.group[0] === '/owner'?
                      'Responsabile di corso':
                      null:
                null
            }
          </span>
          <br />
          {this.props.user.name}
          <br />
          <span
            className='linker'
            style={{
              // color: 'white',
              fontSize: '0.8em'
            }}
            onClick={
              () => {
                keycloak.logout();
              }
            }
          >
            Sign out
          </span>
          {/* <div
            className='circle mrg brd sdw bg-white'
            onClick={
              () => {
                keycloak.logout();
              }
            }
            style={{
              height: '40px',
              width: '40px',
              backgroundImage: `url('${process.env.PUBLIC_URL}/images/account.png')`,
              backgroundSize: '28px',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat'
            }}
          >
          </div> */}
        </div>
      </div>
    );
  }
}

HeaderCmp.propTypes = {
  loading: PropTypes.bool,
  onChanged: PropTypes.func
};

const mapStateToProps = (state) => ({
  user: state.user
});

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch: dispatch,
    updateTextSearch: (text = '') => {
      return dispatch({
        type: 'TEXT_FILTER_UPDATED',
        text: text,
      });
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(HeaderCmp));

// export default withRouter(HeaderCmp);