import React from 'react';
import PropTypes from 'prop-types';
import {
  withRouter
} from "react-router-dom";

import Header from './header';
import CardCourse from '../common/cardcourse';

class Home extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      
    };
  }

  render() {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%'
        }}
      >
        <Header />
        <div
          className='fxfill fxoverflow'
          style={{
            backgroundColor: 'rgb(244, 244, 244)'
          }}
        >
          <div
            className="mdl-typography--headline pdglr mrgtb"
          >
            Riprendi da dove hai lasciato
          </div>
          <div
            className="pdg fxrow"
          >
            <div
              style={{
                margin: '0px 16px'
              }}
            >
              <CardCourse
                action='Riprendi'
                image={process.env.PUBLIC_URL + '/images/iron.jpg'}
                onClick={
                  () => {
                    this.props.history.push(
                      process.env.PUBLIC_URL + "/course/97de2790-4ee0-4676-ba94-699a6d78c6ce"
                    );
                  }
                }
                title={'Strutture a telaio – 1° parte'}
              />
            </div>
            <div
              style={{
                margin: '0px 16px'
              }}
            >
              <CardCourse
                action='Riprendi'
                image={process.env.PUBLIC_URL + '/images/iron.jpg'}
                onClick={
                  () => {
                    this.props.history.push(
                      process.env.PUBLIC_URL + "/course/97de2790-4ee0-4676-ba94-699a6d78c6cf"
                    );
                  }
                }
                title={'Strutture a telaio – 2° parte'}
              />
            </div>
          </div>
          <div
            className="mdl-typography--headline pdglr mrgtb"
          >
            I tuo corsi
          </div>
          <div
            className="pdg fxrow"
          >
            <div
              style={{
                margin: '0px 16px'
              }}
            >
              <CardCourse
                action='Apri'
                image='images/geomatica.jpg'
                title={'Geomatica'}
              />
            </div>
            <div
              style={{
                margin: '0px 16px'
              }}
            >
              <CardCourse
                action='Apri'
                image='images/geologia.jpg'
                title={'Geologia'}
              />
            </div>
            <div
              style={{
                margin: '0px 16px'
              }}
            >
              <CardCourse
                action='Apri'
                image='images/statica.jpg'
                title={'Statica'}
              />
            </div>
          </div>

          <div
            className="mdl-typography--headline pdglr mrgtb"
          >
            Tutti i corsi
          </div>
          <div
            className="pdg fxrow"
          >
            <div
              style={{
                margin: '0px 16px'
              }}
            >
              <CardCourse
                action='Apri'
                image='images/architettura.jpg'
                title={'Architettura'}
              />
            </div>
            <div
              style={{
                margin: '0px 16px'
              }}
            >
              <CardCourse
                action='Apri'
                image='images/geomatica.jpg'
                title={'Geomatica'}
              />
            </div>
            <div
              style={{
                margin: '0px 16px'
              }}
            >
              <CardCourse
                action='Apri'
                image='images/iron.jpg'
                title={'Acciaio II'}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
};

Home.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired
};

export default withRouter(Home);