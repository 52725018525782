import React from 'react';
import PropTypes from 'prop-types';
// import moment from 'moment';

const VideoMetadata = props => {
  console.log("VideoMetadata", props);
  return (
    props.data &&
    <div
      style={{
        fontSize: '1em'
      }}
    >
      {/* <div
        className='linker'
      >
        {props.data.details.collocation.name}
      </div> */}
      {
        props.data.collection !== null
        && props.data.collection.length > 0?
          <div
            className='txt-subtitle'
          >
            {
              props.data.collection.map(
                (collection, idx) => (
                  <div
                    // className="linker"
                    key={`web-bdigital-video-collections-${idx}`}
                  >
                    {collection.name}
                  </div>
                )
              )
            }
          </div>: null
      }
      <div
        className="txt-title"
      >
        {props.data.details.title}
      </div>
      {/* <div
        style={{
          color: '#787878'
        }}
      >
        {props.data.details.typology.name} del {moment(props.data.details.creation_date).format('DD.MM.YYYY')}
        &nbsp;- in {props.data.details.lang.name}
      </div> */}
      {/* <div
        className='pdgtb'
      >
        {props.data.details.description}
      </div> */}
      {
        props.data.keywords
        && props.data.keywords.length?
          <div
            className='fxrow txt-subtitle'
            style={{
              flexWrap: 'wrap'
            }}
          >
            {
              props.data.keywords.map(
                (el, idx) => <div
                  key={`video-metadata-${idx}`}
                  className='linker'
                  style={{
                    paddingRight: '0.2em'
                  }}
                >
                  #{el.text}
                </div>
              )
            }
          </div>: null
      }
      {
        // props.data.details.axs !== null?
        //   <div
        //     className="linker pdgt"
        //   >
        //     <img
        //       alt={props.data.details.axs.name}
        //       src={process.env.PUBLIC_URL + '/images/lic/by-nc-sa.eu.png'}
        //       style={{
        //         height: '31px'
        //       }}
        //     />
        //   </div>: null
      }
      {
        props.data.details.links !== null
        && props.data.details.links.length > 0?
          <div
            style={{
              marginTop: '0.5em'
            }}
          >
            <div
              className="txt-subtitle"
            >
              Collegamenti esterni
            </div>
            {
              props.data.details.links.map(
                (link, lidx) => (
                  <div
                    // className="linker"
                    key={`web-bdigital-video-links-${lidx}`}
                  >
                    <a
                      className="linker"
                      href={link.url}
                      rel="noreferrer"
                      target='_BLANK'
                    >
                      {link.text}
                    </a>
                  </div>
                )
              )
            }
          </div>: null
      }
    </div>
  );
};

VideoMetadata.propTypes = {
  "data": PropTypes.shape({
    "details": PropTypes.shape({
      "id": PropTypes.string,
      "creation": PropTypes.string,
      "title": PropTypes.string,
      "description": PropTypes.string,
      "lang": PropTypes.shape({
        "id": PropTypes.string,
        "name": PropTypes.string
      }),
      "authors": PropTypes.arrayOf(
        PropTypes.shape({
          "id": PropTypes.number,
          "name": PropTypes.string,
          "affiliation": PropTypes.string
        })
      ),
      "collocation": PropTypes.shape({
        "id": PropTypes.string,
        "name": PropTypes.string,
        "description": PropTypes.string,
      }),
      "typology": PropTypes.shape({
        "id": PropTypes.string,
        "name": PropTypes.string,
        "description": PropTypes.string,
      }),
      "axs": PropTypes.shape({
        "id": PropTypes.string,
        "name": PropTypes.string,
        "description": PropTypes.string,
      }),
    }),
    "collection": PropTypes.arrayOf(
      PropTypes.shape({
        "id": PropTypes.string,
        "name": PropTypes.string,
      })
    ),
    "keywords": PropTypes.arrayOf(
      PropTypes.shape({
        "id": PropTypes.number,
        "name": PropTypes.string,
      })
    ),
  })
};

export default VideoMetadata;