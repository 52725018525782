
const initialState = {
  loading: false,

  text: '',

  // data
  keywords: [],
  languages: [],
  typologies: [],
  collections: [],
  collocations: [],
  accesses: [],
  licenses: [],
};

function searchReducer (state = {...initialState}, action){
  switch (action.type) {
    case 'INIT_FILTERS':
      return {
        loading: true,
        ...initialState,
        // languages: [],
        // typologies: [],
        // collections: [],
        // collocations: [],
        // accesses: [],
        // licenses: [],
      };
    case 'INIT_FILTERS_OK':
      return {
        loading: true,
        ...action.data,
      };
    case 'TEXT_FILTER_UPDATED':
      return {
        ...state,
        text: action.text
      };
    default:
      return state
  }
}


export default searchReducer;
