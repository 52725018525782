import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from 'react-router-dom';

import {
  // Home,
  Course,
  Search
} from './pages';

const cpaths = [
  // {
  //   path: process.env.PUBLIC_URL + '/search',
  //   exact: false,
  //   body: Search
  // },
  {
    path: process.env.PUBLIC_URL + '/course/:id',
    exact: true,
    body: Course
  },
  {
    path: process.env.PUBLIC_URL + '/',
    body: Search
  },
];

function App() {
  return (
    <Router>
      <Switch>
        {
          cpaths.map((route, index) => {
            return (
              <Route
                component={route.body}
                exact={route.exact}
                key={index}
                path={route.path}
              />
            );
          })
        }
        <Route
          component={() => (
            <Redirect
              to={{
                pathname: process.env.PUBLIC_URL + "/"
              }}
            />
          )}
        />
      </Switch>
    </Router>
  );
}

export default App;
