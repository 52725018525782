import Keycloak from "keycloak-js";
import { store } from './store';


const kurl = process.env.REACT_APP_KEYCLOAK_URL?
  process.env.REACT_APP_KEYCLOAK_URL:
  "https://bdigital.supsi.ch/auth/";

export const keycloak = Keycloak({
  realm: "bdigital",
  url: kurl,
  clientId: "bdigital-app",
});

const refreshToken = async (minValidity = -1) => {

  try {
    const refreshed = await keycloak.updateToken(minValidity);
    
    if (refreshed) {
      console.debug('Token refreshed YES!!, valid for '
      + Math.round(keycloak.tokenParsed.exp + keycloak.timeSkew - new Date().getTime() / 1000) + ' seconds');

      var o = 'Token Expires:\t\t' + new Date((keycloak.tokenParsed.exp + keycloak.timeSkew) * 1000).toLocaleString() + '\n';
      o += 'Token Expires in:\t' + Math.round(keycloak.tokenParsed.exp + keycloak.timeSkew - new Date().getTime() / 1000) + ' seconds\n';
      
      if (keycloak.refreshTokenParsed) {
          o += 'Refresh Token Expires:\t' + new Date((keycloak.refreshTokenParsed.exp + keycloak.timeSkew) * 1000).toLocaleString() + '\n';
          o += 'Refresh Expires in:\t' + Math.round(keycloak.refreshTokenParsed.exp + keycloak.timeSkew - new Date().getTime() / 1000) + ' seconds';
      }

      console.info(o);
      
    } else {
      console.warn('Token not refreshed NO!! , valid for '
        + Math.round(keycloak.tokenParsed.exp + keycloak.timeSkew - new Date().getTime() / 1000) + ' seconds');
    }
  } catch (err) {
    console.error('Failed to refresh token');
    // window.location.reload();
  }
};

// console.log("SSO Service url: " + kurl);

// keycloak.onTokenExpired = () => {
//   console.log('token expired', keycloak.token);
// }

export const initializeKeycloak = async () => {
  return await keycloak.init({
    onLoad: "login-required",
    checkLoginIframe: false
  }).then(
    authenticated => {
    console.info(
      authenticated?
        'User succefully authenticated':
        'User not authenticated'
    );
    if (authenticated === true) {

      let refreshTockenDuration = null;
      if (keycloak.refreshTokenParsed) {
        refreshTockenDuration = Math.round(
          keycloak.refreshTokenParsed.exp + keycloak.timeSkew - new Date().getTime() / 1000
        );
      }
      
      keycloak.loadUserInfo().then(
        (userInfo) => {
          store.dispatch({type: 'AUTHENTICATED_OK', user: userInfo});

          // Init refresh token interval
          if (refreshTockenDuration !== null) {
            console.log("Configuring session keepalive, every: " + Math.round(refreshTockenDuration/2) + " seconds");
            window.setInterval(async () => {
              console.debug("refreshToken now:");
              refreshToken();
            }, (Math.round(refreshTockenDuration/2) * 1000));
          }
          
        }
      );

    }
  }).catch(function() {
    console.info('failed to initialize');
  });;
};