import React, { Component } from 'react';
import { connect } from 'react-redux';
// import {
//   withRouter
// } from "react-router-dom";
// import PropTypes from 'prop-types';
import Header from './header';

import {
  initSearchPage,
  listVideos,
} from '../actions';
import Filter from '../common/filter';
import VideoCard from '../common/videoCard';

class Search extends Component {

  // static propTypes = {
  //   prop: PropTypes
  // }

  constructor(props) {
    super(props);
    this.state = {
      text: '',
      keywords: [],
      collections: [],
      languages: [],
      typologies: [],
      collocations: [],

      loading: true,
      videos: []
    };

    this.filterVideos = this.filterVideos.bind(this);

    this.handleKeywordChanged = this.handleKeywordChanged.bind(this);
    this.handleCollectionsChanged = this.handleCollectionsChanged.bind(this);
    this.handleLanguageChanged = this.handleLanguageChanged.bind(this);
    this.handleTypologyChanged = this.handleTypologyChanged.bind(this);
    this.handleCollocationChanged = this.handleCollocationChanged.bind(this);
  }

  componentDidMount () {
    this.props.initSearchPage();
    this.filterVideos();
  }

  filterVideos () {
    const {
      text,
      keywords,
      collections,
      languages,
      typologies,
      collocations,
    } = this.state;

    const where = [];

    if (text) {
      // where.push(`_or: [{title: {_ilike: "%${text}%"}}, {description: {_ilike: "%${text}%"}}]`);
      where.push(`title: {_ilike: "%${text}%"}`);
    }

    if (keywords.length > 0) {
      where.push(`vid_keys: {
        key: {_in: [${keywords.join(',')}]}
      }`)
    }

    if (typologies.length > 0) {
      where.push(`typology: {_in: ["${typologies.join('", "')}"]}`)
    }

    if (collections.length > 0) {
      where.push(`
        vid_cats: {
          typ: {_eq: "collection"},
          cat: {_in: ["${collections.join('", "')}"]}
        }
      `)
    }

    if (collocations.length > 0) {
      where.push(`collocation: {_in: ["${collocations.join('", "')}"]}`)
    }

    if (languages.length > 0) {
      where.push(`language: {_in: ["${languages.join('", "')}"]}`)
    }

    this.setState({
      loading: true,
    }, () => {
      listVideos(where).then(
        r => {
          this.setState({
            videos: r.data.bdi_videos,
            loading: false,
          })
        }
      );
    })
  }

  handleKeywordChanged (value) {
    this.setState({
      keywords: value
    }, () => {
      this.filterVideos();
    });
  }

  handleTypologyChanged (value) {
    console.log(value);
    this.setState({
      typologies: value
    }, () => {
      this.filterVideos();
    });
  }

  handleCollectionsChanged (value) {
    console.log(value);
    this.setState({
      collections: value
    }, () => {
      this.filterVideos();
    });
  }

  handleLanguageChanged (value) {
    console.log(value);
    this.setState({
      languages: value
    }, () => {
      this.filterVideos();
    });
  }

  handleCollocationChanged (value) {
    this.setState({
      collocations: value
    }, () => {
      this.filterVideos();
    });
  }

  handleTextSearchUpdate (text, executeFilter = false) {
    this.setState({
      text: text
    }, () => {
      if (executeFilter === true || text === '') {
        this.filterVideos();
      }
    });
  }

  render() {
    const {
      keywords,
      collections,
      languages,
      typologies,
      collocations,
    } = this.props.search;
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%'
        }}
      >
        <Header />
        <div
          className='fxfill fxrow'
          style={{
            // alignItems: 'flex-start',
            backgroundColor: 'rgb(244, 244, 244)',
            overflow: 'hidden',
          }}
        >
          <div
            className='pdg-x2 fxcol fxoverflow'
            style={{
              width: '300px'
            }}
          >
            <div
              className='fxfill'
            >
              <div
                className='txt-title-2 pdgb'
              >
                Cerca nel titolo o descrizione
              </div>
              <div
                className='fxrow pdgb-x2'
              >
                <input
                  onChange={this.handleChange}
                  style={{
                    width: '100%',
                  }}
                  placeholder="Cerca"
                  ref={this.inputField}  
                  type='text'
                  onKeyPress={
                    (ev) => {
                      if (
                        ev.key === 'Enter'
                        && ev.target.value
                      ){
                        this.filterVideos();
                      }
                    }
                  }
                  onChange={
                    ev => {
                      this.handleTextSearchUpdate(ev.target.value)
                    }
                  }
                  value={this.state.text}  
                />
                <button
                  onClick={
                    () => {
                      this.filterVideos();
                    }
                  }
                >
                  🔍
                </button>
              </div>
              <Filter
                title='Collocazione'
                data={collocations}
                onChange={this.handleCollocationChanged}
              />
              <Filter
                title='Parole chiave'
                data={keywords}
                onChange={this.handleKeywordChanged}
              />
              <Filter
                title='Tipologia'
                data={typologies}
                onChange={this.handleTypologyChanged}
              />
              <Filter
                title='Collezioni'
                data={collections}
                onChange={this.handleCollectionsChanged}
              />
              {/* <Filter
                title='Lingua'
                data={languages}
                onChange={this.handleLanguageChanged}
              /> */}
            </div>
          </div>
          <div
            className="fxfill fxoverflow pdg fxrow fxwrap"
            style={{
              padding: '1em',
              alignContent: 'flex-start'
            }}
          >
            {
              this.state.loading === true?
                'Caricamento in corso...': null
            }
            {
              this.state.videos.map(
                (video, idx) => (
                  <VideoCard
                    key={`search-results-${video.id}`}
                    video={{ ...video }}
                    onSelected={
                      selected => {
                        this.props.history.push(
                          process.env.PUBLIC_URL
                          + `/course/${selected.id}`
                        );
                      }
                    }
                  />
                )
              )
            }
          </div>
        </div>
      </div>
    )
  }
};


const mapStateToProps = (state) => ({
  search: state.search
});

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch: dispatch,
    initSearchPage: () => {
      dispatch(initSearchPage());
    },
    updateTextSearch: (text = '') => {
      return dispatch({
        type: 'TEXT_FILTER_UPDATED',
        text: text,
      });
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Search);
