import React from 'react';
import {
  withRouter
} from "react-router-dom";

import HeaderCmp from './headerCmp';

class Header extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(searchText){
    console.log("handleChange: ", searchText);
  }

  render() {
    return (
      <HeaderCmp
        onChanged={this.handleChange}
      />
    );
  }
};

export default withRouter(Header);