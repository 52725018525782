import React from 'react';
import PropTypes from 'prop-types';

CardCourse.propTypes = {
  title: PropTypes.string,
  action: PropTypes.string,
};

function CardCourse(props) {
  return (
    <div
      className="card-square mdl-card mdl-shadow--2dp"
    >
      <div
        className="mdl-card__title mdl-card--expand"
        style={{
          backgroundImage: props.image? `url('${props.image}')`: null,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          padding: '0px'
        }}
      >
        <h2
          className="mdl-card__title-text"
          style={{
            backgroundColor: '#000000a1',
            width: '100%',
            padding: '8px',
          }}
        >
          {props.title}
        </h2>
      </div>
      <div className="mdl-card__supporting-text">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
        Aenan convallis.
      </div>
      <div className="mdl-card__actions mdl-card--border">
        <div
          className="mdl-button mdl-button--colored mdl-js-button mdl-js-ripple-effect"
          onClick={props.onClick}
        >
          {props.action}
        </div>
      </div>
    </div>
  );
}

export default CardCourse;
