import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  withRouter
} from "react-router-dom";
import Header from './header';
import {
  Video
} from '../common';
import {
  getVideo,
  unlockVideo,
  listVideos
} from '../actions';
import VideoMetadata from '../common/videoMetadata';
import VideoCard from '../common/videoCard';



class Course extends Component {

  static propTypes = {
    uuid: PropTypes.string
  }

  constructor(props){
    super(props);
    this.state = {
      isFetching: true,
      isFetchingRelated: false,
      data: null,
      related: [],
      password: ''
    };
    this.loadVideo = this.loadVideo.bind(this);
    this.hasCollections = this.hasCollections.bind(this);
  }

  componentDidMount(){
    const {
      match
    } = this.props;
    this.loadVideo();
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.id !== prevProps.match.params.id) {
      this.setState({
        isFetching: true,
        isFetchingRelated: false,
        data: null,
        related: [],
        password: ''
      }, () => {
        this.loadVideo();
      })
    }
  }

  loadVideo() {
    getVideo(
      this.props.match.params.id
    )
    .then(response => {
      this.setState({
        isFetching: false,
        isFetchingRelated: true,
        related: [],
        data: response.data
      }, () => {
        if (this.hasCollections() === true) {
          const or = [];
          if (
            this.state.data !== null &&
            this.state.data.hasOwnProperty('details') &&
            this.state.data.details !== null
          ) {
            or.push(`id: {_neq: "${this.state.data.details.id}"}`);
          }
          if (this.state.data.collection.length === 1) {
            or.push(`vid_cats: {cat: {_eq: "${this.state.data.collection[0].id}"}}`)
          } else {
            for (let index = 0; index < this.state.data.collection.length; index++) {
              const coll = this.state.data.collection[index];
              or.push(`vid_cats: {cat: {_eq: "${coll.id}"}}`)
            }
            or.push(`{_or: ${or}}`);
          }

          listVideos(or).then(
            r => {
              this.setState({
                related: r.data.bdi_videos,
                isFetchingRelated: false,
              });
            }
          );
        } else {
          this.setState({
            isFetchingRelated: false,
          })
        }
      });
    }).catch(error => {
      console.error(error);
    });
  }

  hasCollections() {
    return (
      this.state.data !== null &&
          this.state.data.hasOwnProperty('collection') &&
          this.state.data.collection !== null &&
          this.state.data.collection.length > 0
    );
  }

  render() {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%'
        }}
      >
        <Header />
        
        <div
          className='fxfill fxoverflow'
          style={{
            backgroundColor: 'rgb(244, 244, 244)',
            padding: '1em 2em 1em 2em',
          }}
        >
          <div
            className="fxrow"
          >
            <div
              className='pdg'
              style={{
                flex: '1 1 100%'
              }}
            >
              {
                this.state.data !== null &&
                this.state.data.hasOwnProperty('details') &&
                this.state.data.hasOwnProperty('status') &&
                this.state.data.details === null &&
                this.state.data.status !== null?
                  <div
                    style={{
                      backgroundColor: '#f4f4f4'
                    }}
                  >
                    {
                      this.state.data.status[0].approved === false?
                        `Questo corso non è ancora stato approvato, 
                        e non è visibile agli studenti`:
                        this.state.data.status[0].availability === 'EXPIRED'?
                          `Questo non è più disponibile`:
                          this.state.data.status[0].access === 'Private'?
                            `Questo corso è disponibile unicamente
                            al suo creatore ed ai responsabili di modulo.`:
                            this.state.data.status[0].access === 'Protected'?
                              <div
                                className='pdg'
                              >
                                Inserire la password per accedere al corso
                                <br />
                                <input
                                  type='password'
                                  autoComplete={false}
                                  style={{
                                    width: '8em',
                                    textAlign: 'center'
                                  }}
                                  onChange={
                                    ev => this.setState({
                                      password: ev.target.value
                                    })
                                  }
                                  value={this.state.password}
                                />
                                <button
                                  onClick={
                                    () => {
                                      unlockVideo(
                                        this.props.match.params.id,
                                        this.state.password,
                                        this.props.user.sub
                                      ).then(
                                        r => {
                                          console.log(r);
                                          if(r.success === true) {
                                            this.setState({
                                              isFetching: true,
                                            }, () => {
                                              this.loadVideo();
                                            })
                                          } else {
                                            alert("Password errata");
                                          }
                                        }
                                      )
                                    }
                                  }
                                >
                                  Accedi
                                </button>
                                <div
                                  className='pdgtb'
                                >
                                  Se non conosci la password puoi richiederla scrivendo
                                  un email ad un responsabile di modulo:
                                </div>
                                <div>
                                  {
                                    this.state.data.status[0].responsible.map(
                                      responsible => (
                                        <div
                                          className='pdgb'
                                        >
                                          <a
                                            className='linker'
                                            href={`mailto: ${responsible.email}?subject=[bDigital] Richiesta accesso&body=Corso: ${this.props.match.params.id}`}
                                          >
                                            {responsible.first_name} {responsible.last_name}
                                          </a>
                                        </div>
                                      )
                                    )
                                  }
                                </div>
                              </div>:
                              null
                    }
                  </div>: null
              }
              {
                (
                  this.state.isFetching === false &&
                  this.state.data !== null &&
                  this.state.data.hasOwnProperty('details') &&
                  this.state.data.details !== null
                ) &&
                <Video
                  file={this.state.data.details.file}
                  poster={`${this.state.data.details.file}.jpg`}
                />
              }
              <div
                className='mrgt'
              >
                {
                  (
                    this.state.isFetching === false &&
                    this.state.data !== null &&
                    this.state.data.hasOwnProperty('details') &&
                    this.state.data.details !== null
                  ) &&
                  <VideoMetadata
                    data={this.state.data}
                  />
                }
              </div>
            </div>
            <div
              className="pdg"
              style={{
                // flex: '1 1 350px',
                minWidth: '500px',
                maxWidth: '500px',
                paddingLeft: '1em'
              }}
            >
              {
                this.state.data !== null &&
                this.state.data.hasOwnProperty('details') &&
                this.state.data.details !== null &&
                (
                  this.state.data.details.link_icorsi !== '' &&
                  this.state.data.details.link_msteams !== ''
                )?
                  <div
                    className='fxcol pdgb'
                  >
                    <div
                      className='txt-title pdgb-x2'
                      style={{
                        whiteSpace: 'nowrap',
                        color: '#444'
                      }}
                    >
                      Risorse collegate
                    </div>
                    {
                      this.state.data.details.link_icorsi !== ''?
                        <div
                          className='pdgb mrgb'
                        >
                          <a
                            href={this.state.data.details.link_icorsi}
                            target='_BLANK'
                          >
                            <img
                              style={{
                                height: '60px'
                              }}
                              src={`${process.env.PUBLIC_URL}/images/icorsi.svg`}
                            />
                          </a>
                        </div>: null
                    }
                    {
                      this.state.data.details.link_msteams !== ''?
                        <div
                          className='pdgb mrgb'
                        >
                          <a
                            href={this.state.data.details.link_msteams}
                            target='_BLANK'
                          >
                            <img
                              style={{
                                height: '60px'
                              }}
                              src={`${process.env.PUBLIC_URL}/images/msteams.png`}
                            />
                          </a>
                        </div>: null
                    }
                  </div>: null
              }
              <div
                className='fxrow'
              >
                <div
                  className='txt-title'
                  style={{
                    whiteSpace: 'nowrap'
                  }}
                >
                  Altri video correlati
                </div>
                <div
                  className='fxfill'
                />
                <div
                  className='linker'
                  style={{
                    whiteSpace: 'nowrap'
                  }}
                  onClick={
                    () => {
                      this.props.history.push(process.env.PUBLIC_URL);
                    }
                  }
                >
                  🔍 Cerca altri corsi
                </div>
              </div>
              {
                this.hasCollections() === true?
                  <div
                    className='fxrow mrgb'
                    style={{
                      flexWrap: 'wrap',
                      color: '#787878',
                    }}
                  >
                    {
                      this.state.data.collection.map(
                        (el, idx) => <div
                          key={`video-metadata-collections-${idx}`}
                          // className='linker'
                          style={{
                            paddingRight: '0.2em',
                            fontSize: '0.9em',
                          }}
                        >
                          {el.name}
                        </div>
                      )
                    }
                  </div>: null
              }
              {
                this.state.isFetchingRelated === true?
                  'caricamento in corso...':
                  this.state.related.length === 0?
                    <span
                      style={{
                        color: '#787878',
                      }}
                    >
                      Nessuno altro corso trovato
                    </span>:
                    this.state.related.map(
                      video => (
                        <VideoCard
                          horizontal
                          key={`related-results-${video.id}`}
                          video={{ ...video }}
                          onSelected={
                            selected => {
                              this.props.history.push(
                                process.env.PUBLIC_URL
                                + `/course/${selected.id}`
                              );
                            }
                          }
                        />
                      )
                    )
              }
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  user: state.user
});

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch: dispatch,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Course));
